import React from "react"
import { Link } from 'gatsby';

const postPreview = ({ post }) => {
    return (
        <div className="md:w-1/2 my-3 sm:pr-4">
            <div className="max-w-4xl px-6 md:px-10 py-6 bg-white rounded-lg shadow-md">
                <div className="flex justify-between items-center">
                    <span className="font-light text-gray-700 text-xs">{ post.createdAt }</span>
                    <Link className="px-2 py-1 bg-main text-xs text-gray-100 font-bold rounded hover:bg-orange-700 "
                          to={'/aktualnosci/kategoria/' + post.category.slug}>{post.category.title}</Link>
                </div>
                <div className="mt-2">
                    <Link to={'/aktualnosci/' + post.category.slug + '/' + post.slug} className="text-2xl text-gray-900 font-bold hover:underline">{post.title}</Link>
                    <p className="mt-2 text-gray-700">{post.excerpt}</p>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <Link to={'/aktualnosci/' + post.category.slug + '/' + post.slug} className="bg-gray-300 hover:bg-gray-400 rounded text-sm px-4 py-2 font-semibold leading-tight text-gray-900">Czytaj dalej</Link>
                    <div>
                        <div className="flex items-center">
                            <img className="h-6 w-6 rounded-full mr-2" srcSet={post.author.avatar.fluid.srcSet} alt={post.author.avatar.title}/>
                            <h1 className="text-gray-900 font-semibold text-sm">{post.author.name}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default postPreview;